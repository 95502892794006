import { NEW_ZEALAND } from '@config'
import { getSlug, slugToId, toNumber } from '@utils'
import { getSuburbLabel } from '@utils/house'
import { capitalize, each, get, has, isArray, isBoolean, isNull, isUndefined, join, keys, last, map, size, some, sortBy, split, take } from 'lodash-es'

export default class SearchRoute {
  constructor() {
    this.reset()
  }

  reset() {
    return this
  }

  clearSuburbs() {
    this.region = []
    this.district = []
    this.suburb = []
    this.street = []
    return this
  }

  setSuburbs(data) {
    if (!data) return this

    const list = isArray(data) ? data : [data]
    each(list, item => {
      let slug = get(item, 'slug')
      if (!slug) {
        slug = getSlug(getSuburbLabel(item), item.id)
        item.slug = slug
      }
      switch (item.level) {
        case 'region':
          this.region.push(item)
          break
        case 'district':
          this.district.push(item)
          break
        case 'suburb':
          this.suburb.push(item)
          break
        case 'street':
          this.street.push(item)
          break
      }
    })
    return this
  }

  getSuburb() {
    return [
      ...this.region,
      ...this.district,
      ...this.suburb
    ]
  }

  // 将数据转成router slug
  toSlug() {

    const keyArr = sortBy(keys(this))
    // 把page属性放到最后
    pushToLast(keyArr, 'page')
    // 把region,district,suburb放到最前面
    pushToFirst(keyArr, 'suburb')
    pushToFirst(keyArr, 'district')
    pushToFirst(keyArr, 'region')

    const list = []
    each(keyArr, key => {
      const val = this[key]
      const str = this.#fieldToSlug(val, key)
      if (!isUndefined(str)) list.push(str)
    })
    // 这里encode，会导致router里面的slug变成%2C
    // return encodeURIComponent(join(list, '_'))
    return join(list, '_')

    // const list = []
    // map(this, (val, key) => {
    //   const str = this.#fieldToSlug(val, key)
    //   if (!isUndefined(str)) list.push(str)
    // })
    // // 这里encode，会导致router里面的slug变成%2C
    // // return encodeURIComponent(join(list, '_'))
    // return join(list, '_')
  }

  // 把router slug转成数据
  fromSlug(slug) {
    this.reset()
    const list = split(decodeURIComponent(slug), '_')
    for (let i = 0; i < list.length; i++) {
      const key = get(list, i)
      const val = get(list, i + 1)
      if (!has(this, key)) continue

      if (isArray(this[key])) {
        if (!val) return
        const arr = split(val, ',')
        const list = []
        each(arr, slug => {
          if (['region', 'district', 'suburb', 'street'].includes(key)) {
            const strs = split(slug, '-')
            const idx = slug.lastIndexOf('-')
            const d = { slug, level: key }
            if (idx === -1) {
              d.id = parseInt(slug)
            } else {
              d.id = parseInt(last(strs))
              d.label = join(map(take(strs, strs.length - 1), item => capitalize(item)), ' ')
            }
            list.push(d)
          } else {
            list.push(toNumber(slug))
          }
        })
        this[key] = sortBy(list)
        i++
      } else if (isBoolean(this[key])) {
        this[key] = true
      } else if (!has(this, val)) {
        this[key] = toNumber(val)
        i++
      }
    }

    return this
  }

  // 添加all new zealand
  pushNewZealand() {
    if (!this.hasSuburbs()) {
      this.setSuburbs([NEW_ZEALAND])
    }
    return this
  }

  // 是否有all new zealand
  hasNewZealand() {
    return some(this.region, item => item.id === NEW_ZEALAND.id)
  }

  // 更新某一组字段数据
  updateFields(data) {
    each(data, (val, key) => {
      this[key] = val
    })
    return this
  }

  // 当前是否有suburbs数据
  hasSuburbs() {
    return size(this.region) > 0 || size(this.district) > 0 || size(this.suburb) > 0 || size(this.street) > 0
  }

  // 把字段转存对应slug
  #fieldToSlug(data, key) {
    if (isArray(data)) {
      if (size(data)) {
        const val = join(sortBy(map(data, item => get(item, 'slug', item)), item => slugToId(item)), ',')
        return join([key, val], '_')
      }
    } else if (isBoolean(data)) {
      if (data) return key
    } else if (!isNull(data) && !isUndefined(data) && data !== '') {
      // 屏蔽page=1
      // if (key === 'page' && data === 1) return
      return join([key, data], '_')
    }
  }
}

// 把某个属性放到最后
function pushToLast(arr, key) {
  const idx = arr.indexOf(key)
  if (idx !== -1) {
    arr.push(arr.splice(idx, 1)[0])
  }
}
// 把某个属性放到最前面
function pushToFirst(arr, key) {
  const idx = arr.indexOf(key)
  if (idx !== -1) {
    arr.unshift(arr.splice(idx, 1)[0])
  }
}
